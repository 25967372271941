//首页 个人中心 的页面路由
export default [

	//用户中心
	{
		path:'user_info',
		component(){
			return import('@/pages/user/user_info.vue')
		}
	},

	//用户流水
	{
		path:'user_info/all_ra_list',
		component(){
			return import('@/pages/user/all_ra_list.vue')
		}
	},

	//充值记录
	{
		path:'user_info/recharge_list',
		component(){
			return import('@/pages/user/recharge_list.vue')
		}
	},
	//项目列表
	{
		path:'user_info/project_list',
		component(){
			return import('@/pages/user/project_admi/list.vue')
		}
	},
]