const my={

	//md5(方法)
	md5:require('md5'),

	//base64
	base64:require('./base64.js').default,

	//各种校验
	check:require('./check.js').default,

	//其他工具
	other:require('./other.js').default,

	//快捷缓存(对象)
	cache:require('./cache.js').default,
	
	//rsa(对象)
	rsa:require('./rsa.js').default,

	//网络板块
	net:require('./net.js').default,

	//七牛图片缓存管理工具
	qiniu:require('./qiniu.js').default,
	
	//文件相关操作的封装
	file:require('./file.js').default,
	
	//excle录入
	excle:require('./excle.js').default,
};

//不得而为之 -> 操蛋的js
my.other.__parent=my
my.cache.__parent=my
my.rsa.__parent=my
my.net.__parent=my
my.qiniu.__parent=my
my.file.__parent=my
my.excle.__parent=my
my.install=function(app){
	this.__app=app;
	delete(this.install)
}

//路由守卫
my.routerBeforeEach=function(to,from,next){

	let app_my=this;

	//初始化状态(1:未初始化,2:初始化中,3:已初始化成功,4:初始化失败)
	let init_status=app_my.$store.state.init_status

	//未开始初始化
	if(init_status==1){

		//开始初始化
		init({
			
			//作用域
			that:app_my,

			//初始化完成
			success:(data)=>{

				//已登录
				if(data.is_login==1){
					//走你
					next()

				}else if(data.is_login==2){//未登录
					
					//去哪里?
					if(to.name!='login'){

						//缓存本来要去的页面地址
						app_my.cache.set({
							_before_login_to_page:{
								fullPath:to.fullPath,
							}
						});

						//前往登录页
						next('/login')
					}else{
						//走你
						next()
					}
				}
			},
			faild:(res)=>{
				
				app_my.other.msg({
					type:'error',
					str:'初始化失败'
				});
			}
		})
	}else if(init_status==2){//初始化中
		
		//缓存要去的页面
		if(to.name!='login'){

			app_my.cache.set({
				_before_login_to_page:{
					fullPath:to.fullPath,
				}
			});
		}
	}else if(init_status==3){//已经初始化
		
		//登录状态
		let is_login=app_my.$store.state.is_login

		//已登录
		if(is_login==1){
			
			//走你
			next()
			
		}else if(is_login==2){//未登录
			
			//去哪里?
			if(to.name!='login'){

				//缓存要去的页面
				app_my.cache.set({
					_before_login_to_page:{
						fullPath:to.fullPath,

					}
				});
				//前往登陆页面
				next()
			}else{
				//走你
				next();
			}
		}
	}
}

let init=function(obj){

	let app_my=obj.that

	//调用初始化接口
	app_my.net.req({
		data:{
			mod:'init',
			ctr:'transit_shipper_webpage_init',
		},
		take_over_control:2,//强制接管(1:接管content,2:接管整个请求返回,无此参数:不接管任何数据)
		callback:(res)=>{
			/*  初始化失败  */
			//网络传输失败
			if(res.status!=200){

				//保存数据
				app_my.$store.commit('end_of_init',{
					init_status:4,
				})

				//初始化失败处理
				obj.faild();
				return;
			}
			//网络传输成功 后端逻辑层返回失败
			if(res.data.code!=0){

				//保存数据
				app_my.$store.commit('end_of_init',{
					init_status:4,
				})

				//初始化失败处理
				obj.faild();
				return;
			}

			//尝试取出 __init_query
			/*
				__init_query编写规则(伪代码):
					数据:	let obj={a:5}
					密码:	let pwd='zyt56'
					步骤1:	let json_str=JSON.stringify(obj)
					步骤2:	let base64_str=base64_encode(json_str)
					步骤3:	
							let first_code=base64_str[0];
							let __init_query=encodeURI(first_code+pwd+base64_str.substring(1))
			*/
			var url_para=window.location.href.split('?')[1];
			var __init_query_obj={}
			var pwd=res.data.msg.agent_para.password
			if(url_para){
				let url_para_kv_arr=url_para.split('&')
				for(let kv_item of url_para_kv_arr){
					let kv_item_arr=kv_item.split('=')
					if(kv_item_arr.length==2){
						if(kv_item_arr[0]=='__init_query'&&kv_item_arr[1]){
							let __init_query_str_arr=kv_item_arr[1].split(pwd)
							let __init_query_str_clear=__init_query_str_arr[0]+__init_query_str_arr[1]
							let __init_query_json=app_my.base64.decode(decodeURIComponent(__init_query_str_clear))
							try{
								__init_query_obj=JSON.parse(__init_query_json);
							}catch(e){}
						}
					}
				}
			}
			console.log(res.data.msg.session_id,888)
			//保存session_id
			localStorage.setItem('__apple__mac__windows__set__',res.data.msg.session_id)
			
			/*  初始化成功  */
			//保存初始化状态
			app_my.$store.commit('end_of_init',{
				init_status:3,
				init_query:__init_query_obj,
				agent_para:res.data.msg.agent_para,
				bucket:res.data.msg.bucket,
			})

			//保存登录状态和登录后信息
			app_my.$store.commit('end_of_login',{
				is_login:res.data.msg.is_login,
				user_info:res.data.msg.user_info,
				main_user_info:res.data.msg.main_user_info,
				// company_info:res.data.msg.user_info.company_info,
				// shipper_set:res.data.msg.user_info.shipper_set,
			})
			
			//已登录的话读取公司信息
			if(res.data.msg.is_login==1){

				//尝试读取货源系统独有数据
				app_my.net.req({
					data:{
						mod:'app_user',
						ctr:'shipper_extend_info_by_shipper',
						get_shipper_set:1,
						get_company:1,
					},
					callback:(shipper_info)=>{

						//缓存数据
						app_my.$store.commit('end_of_login',{
							company_info:shipper_info.company_info,
							shipper_set:shipper_info.shipper_set,
						})
					}
				});
			}
			
			//初始化成功处理
			obj.success(res.data.msg);
		}
	});
}

export default my