//注册的司机和货车信息
export default [

	//车辆注册信息
	{
		path:'truck_list',
		component(){
			return import('@/pages/register_info/truck_list.vue')
		}
	},

	//司机注册信息
	{
		path:'driver_list',
		component(){
			return import('@/pages/register_info/driver_list.vue')
		}
	},
]