export default{
	routes:[{
			path:'*',
			component(){
				return import('@/pages/public/menu.vue')
			}
		},{
			name:'login',
			path:'/login',
			component(){
				return import('@/pages/public/login.vue')
			}
		},{
			name:'index',
			path:'/pages',
			component(){
				return import('@/pages/public/menu.vue')
			},
			children:[
				
				//个人中心部分
				...require('./extend/user.js').default,

				//网点部分
				...require('./extend/outlets.js').default,

				//实时运单管理部分
				...require('./extend/tord_real_time.js').default,
				
				//后录运单管理部分
				...require('./extend/tord_after_time.js').default,
				
				//子账号部分
				...require('./extend/sub_user.js').default,

				//注册信息部分
				...require('./extend/register').default,
			]
		},
	],
};