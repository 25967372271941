import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {

		//主账号菜单折叠
		main_aside_fold:false,

		//初始化
		init_status:1,//初始化状态(1:未初始化,2:初始化中,3:已初始化成功,4:初始化失败)
		init_query:{},//init时通过地址传入的数据
		agent_para:{},//代理信息
		bucket:{},//七牛bucket

		//用户相关
		is_login:2,//是否已登录(1:已登录,2:未登录)
		user_info:{},//用户信息
		shipper_set:{},//货主参数
		company_info:{},//公司信息

		//主账号信息
		main_user_info:{},

	},
	mutations: {
		set_main_aside_fold(state,data){//主菜单是否折叠
			state.main_aside_fold=data?true:false;
		},
		end_of_init(state,data){//初始化之后
			if(data.init_status){
				state.init_status=data.init_status
			}
			if(data.init_query){
				state.init_query=data.init_query
			}
			if(data.agent_para){
				state.agent_para=data.agent_para
			}
			if(data.bucket){
				state.bucket=data.bucket
			}
		},
		end_of_login(state,data){//登陆成功之后
			if(data.is_login){
				state.is_login=data.is_login
			}
			if(data.user_info){
				state.user_info=data.user_info
			}
			if(data.main_user_info){
				state.main_user_info=data.main_user_info
			}
			if(data.shipper_set){
				state.shipper_set=data.shipper_set
			}
			if(data.company_info){
				state.company_info=data.company_info
			}
		},
		end_of_logout(state){//登出之后
			state.is_login=2
			state.user_info={}
			state.shipper_set={}
			state.company_info={}
			state.main_user_info={}
		},
		end_of_sub_user_logout(state){//主账号登出之后
			state.main_user_info={}
		},
		save_user_info(state,data){//保存用户数据
			state.user_info=data
		},
		save_shipper_set(state,data){//保存货主设置
			state.shipper_set=data
		},
		save_company_info(state,data){//保存公司信息
			state.company_info=data
		},
		save_main_user_info(state,data){//保存主账号信息
			state.main_user_info=data
		},
	},
	actions: {
		
	}
})

export default store
