//网点相关 路由
export default [

	//网点相关
	{
		path:'outlets/outlets_list',
		component(){
			return import('@/pages/outlets/outlets_list.vue')
		}
	},


	//授权相关
	{
		path:'outlets/auth_grant_list',
		component(){
			return import('@/pages/outlets/auth_grant_list.vue')
		}
	},
]